<template>
  <div style="width: 100%;">
    <h3 class="pl-4 text-start">Venta</h3>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">
            Producto
          </th>
          <th class="text-left">
            Cant.
          </th>
          <th class="text-left">
            Precio Unit.
          </th>
          <th class="text-left">
            Precio Total
          </th>
          <th class="text-left">
            Acciones
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="p in products"
          :key="p.products"
        >
          <td class="text-left">{{ p.name || p.text }}</td>
          <td class="text-left">{{ p.quantity }}</td>
          <!-- agregar filtro currency -->
          <td class="text-left">${{ p.price }}</td>
          <td class="text-left">${{p.quantity * p.price}}</td>
          <td class="text-left">
            <span style="cursor: pointer;" @click="$emit('removeSaleItemProduct', getProduct(p.products !== undefined ? p.products : p.id))">Quitar</span>
          </td>
        </tr>
        <tr id="noHoverEffect">
          <td v-bind:style="!applyBonification ? 'color: gray;' : ''">Total Bonificado: ${{bonificationTotal}}</td>
          <td>Total Venta: {{saleTotal}}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'NewSaleTable',
  props: {
    headers: Array,
    products: Array,
    productsItems: Array,
    bonificationPercentage: [Number, String],
    applyBonification: Boolean
  },
  methods: {
    getProduct (id) {
      return this.productsItems.find(p => p.value === id)
    },
    getSum (total, num) {
      return total + num
    }
  },
  computed: {
    // Devuelve el porcentaje de bonificación o 0
    bonificationPercentageComputed () {
      return this.applyBonification && this.bonificationPercentage !== undefined ? Number(this.bonificationPercentage) : 0
    },
    // Devuelve el valor total de la bonificación
    bonificationTotal () {
      return ((this.productsTotal * this.bonificationPercentageComputed) / 100).toFixed(2)
    },
    // Calcula el valor total de los productos
    productsTotal () {
      return this.products.map(p => p.price * p.quantity).reduce(this.getSum, 0)
    },
    // Calcula el total de la venta
    saleTotal () {
      return '$' + (this.productsTotal - this.bonificationTotal).toFixed(2)
    }
  }
}
</script>

<style scoped>
#noHoverEffect:hover {
  background: none !important;
}
</style>
