<template>
  <v-card flat :class="$vuetify.breakpoint.xs ? 'mr-0' : 'mr-6'">
    <v-card-title class="pt-6">
      <span class="subtitle-1 font-weight-medium text--secondary">{{
          NewItemTitle()
        }}</span>
    </v-card-title>
    <form @submit.prevent="submit()" novalidate autocomplete="off">
      <v-card-text>
        <div v-if="isProduct">
          <div
            v-for="(item, i) in NewItemInputValues"
            :key="i"
          >
            <v-col :class="{'col-': item.type !== 'images'}">
              <v-select
                v-if="item.type === 'select'"
                v-model="editedItem[item.value]"
                :items="NewItemInputSelect[item.value]"
                :label="item.text"
                :error="errorsProps(item.value)"
                required
                dense
              ></v-select>
              <!-- main image -->
              <div v-else-if="item.type === 'images' && item.value === 'mainImage'" class="pt-0">
                <v-file-input
                  v-model="editedItem[item.value]"
                  :error="errorsProps(item.value)"
                  class="pt-0"
                  placeholder="Imágen principal"
                  label="Imágen principal"
                  prepend-icon="mdi-camera"
                  accept="image/*"
                  @change="loadImageURL"
                  @click:clear="clearImages(item.value)"
                ></v-file-input>
                <div v-if="mainImageUrl.length > 0" class="d-flex flex-row imagesContainer">
                  <div class="imgContainer" v-if="editedItem.mainImage">
                    <div
                      class="deleteImgButton"
                      @click="deleteImage(item.value, i)"
                    >x
                    </div>
                    <v-img class="loadedImage" :src="mainImageUrl[0]"/>
                  </div>
                </div>
              </div>
              <!-- secondary images -->
              <div v-else-if="item.type === 'images' && item.value === 'pictures'" class="pt-0">
                <v-file-input
                  v-model="editedItem[item.value]"
                  :error="errorsProps(item.value)"
                  multiple
                  placeholder="Imágenes secundarias"
                  label="Imágenes secundarias"
                  class="pt-0"
                  prepend-icon="mdi-camera"
                  accept="image/*"
                  @change="loadImagesURL"
                  @click:clear="clearImages(item.value)"
                ></v-file-input>
                <div class="d-flex flex-row imagesContainer">
                  <div class="imgContainer" v-for="(img, i) in editedItem.pictures" :key="i">
                    <div
                      class="deleteImgButton"
                      @click="deleteImage(item.value, i)"
                    >x
                    </div>
                    <v-img class="loadedImage" :src="imageUrls[i]"/>
                  </div>
                </div>
              </div>
              <v-textarea
                v-else-if="item.type === 'long-text'"
                v-model="editedItem[item.value]"
                :label="item.text"
                :error="errorsProps(item.value)"
                required
                dense
              ></v-textarea>
              <v-text-field
                v-else
                v-model="editedItem[item.value]"
                :label="item.text"
                :error="errorsProps(item.value)"
                required
                dense
              ></v-text-field>
            </v-col>
          </div>
        </div>
        <div v-else-if="isSale">
          <v-row>
            <v-col
              :cols="item.col"
              v-for="(item, i) in NewItemInputValues.filter(v => v.type !== 'hidden')"
              :key="i"
            >
              <!-- <v-col :class="{'col-': item.type !== 'images'}"> -->
              <v-select
                v-if="item.type === 'select' && !item.fromSale"
                v-model="editedItem[item.value]"
                :items="NewItemInputSelect[item.value]"
                :label="item.text || item.name"
                :error="errorsProps(item.value)"
                required
                dense
                class="text-left"
              ></v-select>
              <v-autocomplete
                v-if="item.type === 'autocomplete'"
                v-model="editedItem[item.value]"
                :items="NewItemInputSelect[item.value]"
                :label="item.text"
                :error="errorsProps(item.value)"
                :required="products.length === 0"
                dense
              >
                {{
                  editedItem[item.value] !== undefined && NewItemInputSelect[item.value].find(i => i.value === editedItem[item.value])
                    ? stock = NewItemInputSelect[item.value].find(i => i.value === editedItem[item.value]).stock
                    : stock = ''
                }}
                {{
                  editedItem[item.value] !== undefined && NewItemInputSelect[item.value].find(i => i.value === editedItem[item.value])
                    ? price = NewItemInputSelect[item.value].find(i => i.value === editedItem[item.value]).actual_price
                    : price = ''
                }}
<!--                <template v-slot:append-outer>
                  {{ 'stock: ' + stock }} &#45;&#45;&#45;&#45; {{item.col}}
                  <br>
                  {{ 'price: ' + (price !== '' ? '$' : '') + price }}
                </template>-->
              </v-autocomplete>
              <v-text-field
                v-else-if="!item.fromSale && item.type !== 'hidden'"
                v-model="editedItem[item.value]"
                :label="item.text"
                :error="errorsProps(item.value)"
                :required="products.length === 0"
                dense
                class="text-left"
              >
              </v-text-field>
            </v-col>
            <v-col class="col-1 col-total">
              <span>Stock: {{stock}}</span>
            </v-col>
            <v-col class="col-1 col-total">
              <span>Precio: {{(price !== '' ? '$' : '') + price}}</span>
            </v-col>
            <v-col class="col-1 col-total">
              <!-- agregar filtro currency -->
              total: ${{ totalComputed }}
            </v-col>
            <v-col class="col col-1">
              <v-btn
                color="white"
                icon
                class="ml-auto mr-2 plusButton"
                @click="addSaleItem"
              >+
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="!isEditing">
            <v-col cols="3">
              <v-checkbox
                label="Aplicar descuento"
                v-model="editedItem.applyBonification"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                :disabled="!editedItem.applyBonification"
                v-model="editedItem.bonification_percentage"
                :error="errorsProps('bonification_percentage')"
                label="% de Bonificación"
                dense
                class="text-left"
                type="number"
              >% de Descuento</v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <NewSaleTable
              :products="products"
              :productsItems="NewItemInputSelect.products"
              :bonificationPercentage="editedItem.bonification_percentage"
              :applyBonification="editedItem.applyBonification"
              @removeSaleItemProduct="removeSaleItemProduct"
            />
            <v-col
              :cols="item.col"
              v-for="(item, i) in NewItemInputValuesSales"
              :key="i"
            >
              <v-select
                v-if="item.type === 'select' && item.fromSale"
                v-model="editedItem[item.value]"
                :items="NewItemInputSelect[item.value]"
                :label="item.text"
                :error="errorsProps(item.value)"
                required
                dense
                class="pt-8"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <v-row v-else>
          <v-col
            cols="2"
            sm="6"
            :md="NewItemInputValues.length > 4 ? '4' : null"
            v-for="(item, i) in NewItemInputValues"
            :key="i"
          >
            <v-select
              v-if="item.type === 'select'"
              v-model="editedItem[item.value]"
              :items="NewItemInputSelect[item.value]"
              :label="item.text"
              :error="errorsProps(item.value)"
              required
              dense
            ></v-select>
            <div v-else-if="item.type === 'images'" class="pt-0">
              <v-file-input
                v-model="editedItem[item.value]"
                :error="errorsProps(item.value)"
                class="pt-0"
                prepend-icon="mdi-camera"
                accept="image/*"
                label="Imágenes"
                clearable
                counter
                multiple
                truncate-length="15"
              ></v-file-input>
            </div>
            <v-text-field
              v-else
              v-model="editedItem[item.value]"
              :label="item.text"
              :error="errorsProps(item.value)"
              required
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-for="(item, i) in btnNewItemProps"
          :key="i"
          :color="item.style"
          :text="item.bgOff"
          :loading="item.event === 'save' ? loadingSave : false"
          @click="item.type === 'submit' ? submit() : $emit(item.event)"
        >{{ item.text }}
        </v-btn
        >
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
import NewSaleTable from '@/components/views/dataTableOne/layoutTwo/newItem/NewSaleTable.vue'
import { mapActions } from 'vuex'

export default {
  name: 'NewItem',
  components: { NewSaleTable },
  props: {
    isEditing: {
      type: Boolean,
      default: false
    },
    isProduct: {
      type: Boolean,
      default: false
    },
    isSale: {
      type: Boolean,
      default: false
    },
    openNewItem: Boolean,
    editedItem: Object,
    NewItemTitle: Function,
    NewItemInputValues: Array,
    NewItemInputSelect: Object,
    NewItemInputValuesSales: Array,
    loadingSave: {
      type: Boolean,
      default: false
    },
    btnNewItemProps: {
      type: Array,
      default () {
        return [
          {
            text: 'Cancelar',
            event: 'close',
            style: 'grey lighten-1',
            bgOff: true,
            type: 'button'
          },
          {
            text: 'Aceptar',
            event: 'save',
            style: 'primary',
            bgOff: false,
            type: 'submit'
          }
        ]
      }
    },
    errorsValidationProps: {
      type: Function
    },
    validationsProps: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    isError: false,
    image: undefined,
    mainImageUrl: [],
    imageUrls: [],
    products: [],
    price: 0,
    stock: 0,
    total: 0,
    applyBonification: false
  }),
  validations () {
    return this.validationsProps
  },
  mounted () {
    if (this.isEditing) {
      if (this.isSale) {
        this.formatSaleProducts(this.editedItem.products)
      } else {
        if (this.editedItem.mainImage.length > 0) {
          this.mainImageUrl[0] = this.editedItem.mainImage[0].link
        }
        if (this.editedItem.pictures.length > 0) {
          this.editedItem.pictures.forEach(p => this.imageUrls.push(p.link))
        }
      }
    }
  },
  computed: {
    totalComputed () {
      return this.editedItem?.quantity && this.price && this.editedItem.bonification_percentage && this.editedItem.bonification_percentage !== 0 && this.editedItem.bonification_percentage !== '' && this.editedItem.bonification_percentage !== undefined
        ? (this.editedItem.quantity * this.price) - (this.editedItem.quantity * this.price * this.editedItem.bonification_percentage / 100)
        : this.editedItem?.quantity && this.price
          ? this.editedItem.quantity * this.price
          : 0
    }
  },
  watch: {
    openNewItem () {
      this.products = []
      if (!this.openNewItem) {
        this.$v.$reset()
      }
    },
    isEditing () {
      if (this.isEditing) {
        if (this.isSale) {
          this.formatSaleProducts(this.editedItem.products)
        } else {
          this.imageUrls = []
          this.mainImageUrl = []
          if (this.editedItem.mainImage.length > 0) {
            this.mainImageUrl[0] = this.editedItem.mainImage[0].link
          }
          if (this.editedItem.pictures.length > 0) {
            this.editedItem.pictures.forEach(p => this.imageUrls.push(p.link))
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['handleAlert']),
    // usar este método al montar si es edited
    loadImageURL (file) {
      if (file) {
        if (file.length !== 0) {
          const f = URL.createObjectURL(file)
          this.mainImageUrl.push(f)
        }
      }
    },
    // revisar
    loadImagesURL (file, isEditing) {
      if (file.length !== 0) {
        this.imageUrls = []
        if (!isEditing) {
          if (file.length > 1) {
            file.forEach((fi, i) => {
              const f = URL.createObjectURL(fi)
              this.imageUrls.push(f)
            })
          } else {
            const f = URL.createObjectURL(file[0])
            this.imageUrls.push(f)
          }
        }
      }
    },
    clearImages (val) {
      if (val === 'pictures') {
        // limpiar todas menos la main
        // props: isMain, isClear, data
        this.$emit('addImagesToDelete', false, true, this.editedItem.pictures)
        this.imageUrls = []
        this.editedItem.pictures = this.editedItem.pictures.filter(p => p.is_main === 1)
      } else {
        this.$emit('addImagesToDelete', true, true, this.editedItem.mainImage)
        this.editedItem.pictures = this.editedItem.pictures.filter(p => p.is_main === 0)
        this.mainImageUrl = []
        this.editedItem.mainImageUrl = []
      }
    },
    deleteImage (val, index) {
      if (val === 'pictures') {
        // borrar 1 picture
        this.$emit('addImagesToDelete', false, false, this.editedItem.pictures[index], index)
        this.editedItem.pictures.splice(index, 1)
        this.imageUrls.splice(index, 1)
      } else {
        // borrar mainImage
        this.$emit('addImagesToDelete', true, false, this.editedItem.mainImage[0])
        this.editedItem.pictures.splice(index, 1)
        this.mainImageUrl = []
        this.editedItem.mainImage = []
      }
    },
    formatSaleProducts (products) {
      this.products = []
      products.forEach(p => {
        this.products.push(p)
      })
    },
    submit () {
      if (this.validationsProps) {
        this.$v.editedItem.$touch()
        if (!this.$v.editedItem.$error) {
          this.isSale
            ? this.products.length > 0
              ? this.$emit('save', this.products, this.editedItem)
              : this.handleMessageErrorApi('Falta cargar productos a la venta')
              // console.error('Falta cargar productos a la venta')
            : this.$emit('save')
        } else {
          console.error('error', this.$v.editedItem)
        }
      } else {
        console.error('error')
      }
    },
    errorsProps (value) {
      if (this.errorsValidationProps) {
        if (!this.$v.editedItem[value].$dirty) return false
        return this.errorsValidationProps(value, this.$v.editedItem[value] !== '' ? this.$v.editedItem[value] : 'Error guardando producto')
      } else {
        return false
      }
    },
    addSaleItem () {
      // validar datos
      if (!this.editedItem.$dirty && !this.editedItem.$error && this.editedItem.products !== undefined && this.editedItem.quantity !== undefined) {
        // const index = this.products.findIndex(item => item.products === this.editedItem.products || this.editedItem.id)
        // if (index !== -1) {
        //   this.products[index].quantity += Number(this.editedItem.quantity)
        //   this.products[index].price = parseFloat(this.price)
        //   this.products[index].total += parseFloat(this.totalComputed)
        // } else {
        //   const item = {
        //     id: this.editedItem.products,
        //     products: this.editedItem.products,
        //     quantity: Number(this.editedItem.quantity),
        //     price: parseFloat(this.price),
        //     total: parseFloat(this.totalComputed)
        //   }
        //   this.products.push(item)
        // }
        // this.$emit('clearEditedItemProduct')
        const prod = this.NewItemInputSelect.products.find(item => {
          return (
            // (item.products === this.editedItem.products || this.editedItem.id) ||
            (item.value === this.editedItem.products)
          )
        })
        this.products.push({
          // id: this.editedItem.id,
          id: prod.value,
          products: this.editedItem.products,
          quantity: Number(this.editedItem.quantity),
          price: parseFloat(this.price),
          total: parseFloat(this.totalComputed),
          text: prod.text || 'Sin nombre asignado'
        })
        this.price = 0
        this.editedItem.products = ''
        this.editedItem.quantity = ''
      } else {
        this.handleAlert({ value: true, text: 'Debe elegir un producto y cantidad válidos.', color: 'error' })
      }
    },
    removeSaleItemProduct (item) {
      const index = this.products.findIndex(p => p.products === item.value || p.id === item.value)
      if (index !== -1) {
        this.products.splice(index, 1)
      }
      this.$emit('removeItem', item)
    },
    handleMessageSuccessApi (message) {
      this.alertColor = 'success'
      this.alertMessage = message
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    },

    handleMessageErrorApi (error) {
      this.alertColor = 'error'
      if (error.status === 401) {
        this.signout()
        this.alertMessage = 'Sesión caducada'
      } else {
        if (error.status === 422) {
          const firstError = Object.keys(error.data.errors)[0]
          this.alertMessage = error.data.errors[firstError][0]
        } else {
          this.alertMessage = error.status
        }
      }
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    }
  }
}
</script>

<style lang="scss" scoped>
.imagesContainer {
  flex-wrap: wrap;
}

.imgContainer {
  position: relative;
  max-width: 25%;
  height: auto;
  padding: 1em;
}

.deleteImgButton {
  position: absolute;
  line-height: 1.18;
  background-color: #EDA6AD;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  border: none;
  top: 8px;
  right: 10px;
  z-index: 300;
  transition-duration: .2s;
}

.deleteImgButton:hover, .plusButton:hover {
  background-color: #cc017b;
}

.col-total {
  margin: 4px 0 4px 0px;
}

.plusButton {
  background-color: #EDA6AD;
  color: white;
}
</style>
