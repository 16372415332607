<template>
    <v-toolbar flat>
        <v-toolbar-title>{{ tableTitle() }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="controlOpenNew"
            :max-width="isProductsOrSales ? '85%' : '500px'"
            content-class="no-x-scroll"
            v-if="dialog"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    {{ toolbarBtnText() }}
                </v-btn>
            </template>
            <slot name="newItem"></slot>
        </v-dialog>
    </v-toolbar>
</template>

<script>
export default {
    name: "ToolbarInsideTable",
    props: {
        openNewItem: Boolean,
        tableTitle: Function,
        toolbarBtnText: Function,
        dialog: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isProductsOrSales:
                this.$route.name === "Products" || this.$route.name === "Sales",
        };
    },
    computed: {
        controlOpenNew: {
            get() {
                return this.openNewItem;
            },
            set(value) {
                this.$emit("handleNewItem", value);
            },
        },
    },
};
</script>

<style lang="scss">
.no-x-scroll {
    overflow-x: hidden;
}
.maxW85 {
    max-width: 85% !important;
}
</style>
